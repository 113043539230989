export default class AdminApi {
  /**
   * @param {ApiConnector} connector
   */
  constructor(connector) {
    this.connector = connector
  }

  createModelEntry = (url, data) => {
    return this.connector.post(url, data ? { data } : undefined)
  }

  createModelEntryFromFile = (url, file) => {
    const formData = new FormData()
    formData.append('fileData', file)

    return this.connector.put(url, formData)
  }

  updateModelEntry = (path, data, method = 'PATCH', lang) => {
    return this.connector[method.toLowerCase()](
      path,
      {
        set: data,
      },
      lang ? { headers: { 'x-language': lang } } : undefined,
    )
  }

  uploadInlineButtonData = (url, file, settings) => {
    const formData = new FormData()
    formData.append('data', file)
    formData.append('settings', JSON.stringify(settings || {}))
    return this.connector.put(url, formData)
  }

  uploadModelData = (url, file) => {
    const formData = new FormData()
    formData.append('csvData', file)
    return this.connector.put(url, formData)
  }

  downloadModel = url => {
    window.location.href = `${this.connector.baseUrl}${url}`
  }

  login = data => {
    return this.connector.post(`/@/auth`, data)
  }

  versionInfo = () => {
    return this.connector.get(`/@/versionInfo`)
  }

  logout = async () => {
    let { request } = this.connector.post(`/@/logout`)
    await request
    window.location.reload(true)
  }

  resetPassword = data => {
    return this.connector.post(`/@/resetPassword`, data)
  }

  setPassword = data => {
    return this.connector.post(`/@/setPassword`, data)
  }

  uploadMedia = (url, file, tags) => {
    const formData = new FormData()
    formData.append('uploadData', file)
    formData.append('uploadDataName', file.name)
    formData.append('tags', tags)
    const config = { headers: { 'x-file-name': encodeURI(file.name) } }

    return this.connector.post(url || '/@/media/upload', formData, config)
  }

  getMedias = (params = {}) => {
    const queryString = Object.keys(params)
      .filter(key => params[key])
      .map(key => `${key}=${params[key]}`)
      .join('&')

    const urlParameters = queryString.length > 4 ? `?${queryString}` : ''

    return this.connector.get(`/@/media${urlParameters}`)
  }

  getUserMedia = id => {
    return this.connector.get(`/@/userChallengeMedia/${id}`)
  }

  updateMedia = (_id, data) => {
    return this.connector.patch(`/@/media/${_id}`, data)
  }

  deleteMedia = _id => {
    return this.connector.delete(`/@/media/${_id}`)
  }

  getReferenceSuggestions = (collection, type) => {
    return this.connector.get(
      `/@/~/${collection}?context=reference${type !== undefined ? `&type=${type}` : ''}`,
    )
  }

  download = url => {
    window.location.href = `${this.connector.baseUrl}${url}`
  }

  getModelListData = (collection, query) => {
    return this.connector.get(`/@/~/${collection}?${query}`)
  }

  getSegmentationOptions = () => {
    return this.connector.get(`/@/segmentationOptions`)
  }

  getSurveyQuestionTargetOptions = questionId => {
    return this.connector.get(`/@/getSurveyQuestionTargetOptions/${questionId}`)
  }

  customizeColumns = (columns, modelName) => {
    return this.connector.put(`/@/customize/${modelName}`, { columns })
  }

  resolveIssue = hash => {
    return this.connector.post(`/@/resolveIssue`, { hash })
  }

  msalLogin = () => {
    window.location.href = `${this.connector.baseUrl}/@/msal`
  }
}
