const iconAndColorByStatus = {
  submitted: { icon: 'hourglass_empty', className: 'text-grey-400' }, // 'hourglass_empty' 'more_horiz'
  processing: { icon: 'find_in_page', className: 'text-grey-400' }, // 'search' 'pageview' 'supervisor_account'
  dispatched: { icon: 'local_shipping', className: 'text-grey-400' },
  failed: { icon: 'block', className: 'text-red' },
  succeeded: { icon: 'check_circle_outline', className: 'text-accent-400' },
  pending: { icon: 'hourglass_empty', className: 'text-grey-400' },
  partiallydelivered: { icon: 'hourglass_empty', className: 'text-grey-400' },
  delivered: { icon: 'check_circle_outline', className: 'text-accent-400' },
  collected: { icon: 'check_circle_outline', className: 'text-accent-400' },
  cancelled: { icon: 'block', className: 'text-red' },
  rejected: { icon: 'block', className: 'text-red' },
  inactive: { icon: 'block', className: 'text-red' },
  approved: { icon: 'check_circle_outline', className: 'text-accent-400' },
  active: { icon: 'check_circle_outline', className: 'text-accent-400' },
  deleted: { icon: 'delete', className: 'text-red' },

  inbound: { icon: 'call_received', className: 'text-blue' },
  outbound: { icon: 'call_made', className: 'text-accent-500' },

  invoiced: { icon: 'check_circle_outline', className: 'text-accent-400' },

  //TODO: icon for "generic" statuses ?
  generic1: { icon: 'check_circle_outline', className: 'text-accent-400' },
  generic2: { icon: 'check_circle_outline', className: 'text-accent-400' },

  draft: { icon: 'create', className: 'text-grey-400' },
  activated: { icon: 'send', className: 'text-accent-200' },
  completed: { icon: 'check_circle', className: 'text-green' },

  email: { icon: 'email', className: 'text-grey-400' },
  sms: { icon: 'sms', className: 'text-grey-400' },
  push: { icon: 'tab', className: 'text-grey-400' },
  webhook: { icon: 'code', className: 'text-grey-400' },
  notificationcenter: { icon: 'notifications', className: 'text-grey-400' },
  newcontent: { icon: 'insert_drive_file', className: 'text-grey-400' },
  reminders: { icon: 'calendar_today', className: 'text-grey-400' },
  updatesandchanges: { icon: 'update', className: 'text-grey-400' },

  exportdata: { icon: 'cloud_upload' },
  changehook: { icon: 'rss_feed' },
  importdata: { icon: 'cloud_download' },
  syncfilessftp: { icon: 'file_copy' },

  article: { icon: 'notes' },
  quiz: { icon: 'question_answer' },
  upload: { icon: 'backup' },
  // b2b2c types
  lead: { icon: 'account_circle' },
  existing: { icon: 'person' },
  anonymous: { icon: 'no_accounts' },
  leadrepeat: { icon: 'switch_account' },
  purchase: { icon: 'shopping_card' },
  start_trial: { icon: 'arrow_circle_right' },
  end_trial: { icon: 'arrow_circle_left' },
}

const getIconAndColorByStatus = status => {
  return iconAndColorByStatus[status] || { icon: 'flash_on', className: 'text-grey-300' }
}

const constantFilters = {
  CLEAR_FILTERS: 'clear filters',
}

export { getIconAndColorByStatus, constantFilters }
